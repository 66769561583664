// eslint-disable-next-line max-classes-per-file
import { ViewType } from '~/app/shared/enums/view-type.enum';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterValueGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-value-generic.type';
import { AdvancedFilterValue } from '~/app/shared/types/advanced-filter/advanced-filter-value.type';
import {
    SearchUniverseShareSearchRequestModel,
} from '~/app/shared/types/api/search-universe/search-universe-share-search-request-model.type';
import { SearchUniverses } from '~/app/shared/types/search/search-universes.type';
import { DisplayValue } from '~/app/shared/types/sort-display/display-value.type';
import { SortValue } from '~/app/shared/types/sort-display/sort-value.type';

export class GetPromotedSharesBySubSegmentationIdAction {
    static readonly type = '[SharesList] Get promoted shares by sub-segmentation id';
}

export class SearchAction {
    static readonly type = '[SharesList] Search shares';

    constructor(public reset: boolean = false) {}
}

export class EmptyShares {
    static readonly type = '[SharesList] Empty shares';

    constructor(public sharesId: Array<number> = []) {}
}

export class AutocompleteSearchAction {
    static readonly type = '[SharesList] Autocomplete search';

    constructor(
        public q: string,
        public resetFilters: boolean,
        public resetSubsegmentations: boolean,
    ) {}
}

export class GetMetaDataAction {
    static readonly type = '[SharesList] Get meta-data';

    public constructor(
        public searchUniverse: number | undefined = undefined,
        public filters?: AdvancedFilterValueGeneric[],
    ) {}
}

export class GetSearchUniversesAction {
    static readonly type = '[SharesList] Get all search universes';
}

export class GetSearchUniverseAction {
    static readonly type = '[SharesList] Get one search universes';

    constructor(public universeId: number) {}
}

export class CreateSearchUniverseByShareIdsAction {
    static readonly type = '[ShareList] Create search universe by share ids';

    constructor(
        public name: string,
        public sharesId: Array<number>,
        public mustStay: boolean = false,
        public emptySelected: boolean = false,
    ) {}
}

export class CreateSearchUniverseByShareSearchAction {
    static readonly type = '[ShareList] Create search universe by share search';

    constructor(
        public name: string,
        public shareSearch: SearchUniverseShareSearchRequestModel,
        public mustStay: boolean = false,
        public emptySelected: boolean = false,
        public numberShares: number = 0,
    ) {}
}

export class UpdateSearchUniverseAction {
    static readonly type = '[ShareList] Update list';

    constructor(public id: number, public name: string) {}
}

export class DeleteSearchUniverseAction {
    static readonly type = '[ShareList] Delete list';

    constructor(public universeId: number) {}
}

export class ChangeSearchUniverseAction {
    static readonly type = '[ShareList] Change current search universe';

    constructor(public universeId: number) {}
}

export class GetSubSegmentationsWithSharesAction {
    static readonly type = '[SharesList] Get sub-segmentations with shares';
}

export class GetRiskIndicatorsAction {
    static readonly type = '[SharesList] Get risk-indicators of shares';

    constructor(public sharesId: Array<number>) {}
}

export class ChangeSubSegmentationAction {
    static readonly type = '[SharesList] Change current sub-segmentation';

    constructor(public subSegmentationId: number) {}
}

export class GetSubSegmentationsAction {
    static readonly type = '[SharesList] Get subsegmentations';

    constructor(public args: {
        q?: string,
        filters?: AdvancedFilterValueGeneric[],
        searchUniverse?: number,
        searchUniverses?: SearchUniverses,
        subSegmentation?: string,
    }) {}
}

export class AddShareListAction {
    static readonly type = '[SharesList] Add share list';

    constructor(public key: string, public overrideList = false, public extraFilters: AdvancedFilterGeneric[] = []) { }
}

export class ChangeActiveShareListAction {
    static readonly type = '[SharesList] Change active share list';

    constructor(public key: string) { }
}

export class CloseShareListAction {
    static readonly type = '[SharesList] Close share list';

    constructor(public key: string) { }
}

export class ChangeSelectionAction {
    static readonly type = '[SharesList] Change selected shares';

    constructor(public shareId: number) {}
}

export class SelectAllAction {
    static readonly type = '[SharesList] selected all shares';
}

export class UnselectAllAction {
    static readonly type = '[SharesList] unselected all shares';
}

export class ResetCurrentDisplayValuesAction {
    static readonly type = '[SharesList] reset current display values';

    constructor(public resetSearch: boolean = false) {}
}

export class SaveDefaultDisplayValuesAction {
    static readonly type = '[SharesList] save default display values';

    constructor(public displayValues: DisplayValue[]) {}
}

export class ApplyDisplayValuesAction {
    static readonly type = '[SharesList] apply display values';

    constructor(public displayValues: DisplayValue[]) {}
}
export class SetIsTrendingAction {
    static readonly type = '[SharesList] set isTrending value';

    constructor(public isTrending: boolean) {}
}

export class ApplySortValuesAction {
    static readonly type = '[SharesList] apply sort values';

    constructor(public sortValues: SortValue[]) {}
}

export class SetFiltersAction {
    static readonly type = '[SharesList] Set Filters';

    constructor(public filters: AdvancedFilterValue[]) {}
}

export class SetSelectedSavedSearchIdAction {
    static readonly type = '[SharesList] Set current saved search id';

    constructor(public selectedSavedSearchId: number) {}
}
export class ResetSelectedSavedSearchIdAction {
    static readonly type = '[SharesList] Reset current saved search id';
}

export class ResetFiltersAction {
    static readonly type = '[SharesList] Reset Filters';
}

export class GetSavedSearchesAction {
    static readonly type = '[SharesList] Get Saved Searches';

    constructor(public library ?: string | { id: number, label: string }, public forceUpdate: boolean = false) {
    }
}

export class PostSavedSearchAction {
    static readonly type = '[SharesList] Post Saved Search';

    constructor(
        public library: string,
        public filters: AdvancedFilterGeneric[],
    ) {}
}

export class PutSavedSearchAction {
    static readonly type = '[SharesList] Put Saved Search';

    constructor(
        public library: { id: number, label: string },
        public filters: AdvancedFilterGeneric[],
    ) {}
}

export class DeleteSavedSearchAction {
    static readonly type = '[SharesList] Delete Saved Search';

    constructor(public id: number) {}
}

export class SetViewTypeAction {
    static readonly type = '[SharesList] Set View Type';

    constructor(public viewType: ViewType) {}
}

export class AddSharesToSearchUniverseByShareSearchAction {
    static readonly type = '[SharesList] Add shares to search universe based on share search';

    constructor(
        public universeId: number,
        public shareSearch: SearchUniverseShareSearchRequestModel,
        public emptySelected: boolean = false,
    ) {}
}

export class AddSharesToSearchUniversesByShareSearchAction {
    static readonly type = '[SharesList] Add shares to search universes based on share search';

    constructor(
        public universesIds: number[],
        public shareSearch: SearchUniverseShareSearchRequestModel,
        public clearSelected = true,
        public refreshUniverses = true,
    ) {}
}

export class AddSharesToSearchUniverseByShareIdsAction {
    static readonly type = '[SharesList] Add shares to search universe based on share ids';

    constructor(
        public universeId: number,
        public sharesId: Array<number>,
        public emptySelected: boolean = false,
    ) {}
}

export class AddSharesToSearchUniversesByShareIdsAction {
    static readonly type = '[SharesList] Add shares to search universes based on share ids';

    constructor(public universesIds: number[], public sharesIds: number[], public clearSelected = true, public refreshUniverses = true) {}
}

export class AddShareAtIndexAction {
    static readonly type = '[SharesList] Add share to list at index';

    constructor(public shareId: number, public index: number) {}
}
