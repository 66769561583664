import {
    HttpBackend,
    HttpClient,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    map,
    switchMap,
} from 'rxjs/operators';

import { CompanyUpdateRequest } from '~/app/shared/types/company-update-request.type';
import { Company } from '~/app/shared/types/company.type';
import { MediaS3 } from '~/app/shared/types/media-s3.type';

@Injectable({
    providedIn: 'root',
})
export class CompaniesService {
    private httpBase: HttpClient;

    constructor(
        private http: HttpClient,
        private backend: HttpBackend,
    ) {
        this.httpBase = new HttpClient(backend);
    }

    public uploadLogo(file: File): Observable<Readonly<string>> {
        return this.http.get<Readonly<MediaS3>>('/companies/upload-logo', { params: { contentType: file.type } })
            .pipe(
                switchMap((s3Document: MediaS3) => this.httpBase.put<Readonly<MediaS3>>(s3Document.presignedUrl, file, {
                    headers: { 'Content-Type': file.type },
                })
                    .pipe(
                        map(() => s3Document.fileKey),
                    )),
            );
    }

    public updateCompanies(data: CompanyUpdateRequest) {
        return this.http.patch<Company>('/companies', data);
    }
}
