import {
    HttpBackend,
    HttpClient,
    HttpContext,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moized } from 'moize';
import {
    Observable,
    of,
    switchMap,
} from 'rxjs';
import { map } from 'rxjs/operators';

import { NO_INTERCEPTOR_ERROR } from '~/app/core/constants/common.constants';
import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { createContactForRequest } from '~/app/shared/services/contacts-utils/contacts-utils.service';
import { CollectionOfContacts } from '~/app/shared/types/contacts/collection-of-contacts.type';
import { ContactRequestUpdate } from '~/app/shared/types/contacts/contact-request-update.type';
import { Contact } from '~/app/shared/types/contacts/contact.type';
import { SearchMetaData } from '~/app/shared/types/search/search-metadata.type';
import { SearchQueryBody } from '~/app/shared/types/search/search-query-body.type';
import {
    formatMetadatasContact,
} from '~/app/shared/utils/metadata/metadata.utils';

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    private httpBase: HttpClient;

    constructor(
        private http: HttpClient,
        private backend: HttpBackend,
    ) {
        this.httpBase = new HttpClient(backend);
    }

    getContact(id: number): Observable<Readonly<Contact>> {
        return this.http.get<Readonly<Contact>>(`/contacts/${id}`);
    }

    deleteContact(id: number) {
        return this.http.delete(`/contacts/${id}`);
    }

    updateContact(id: number, fields: Partial<ContactRequestUpdate>) {
        return this.http.patch<Readonly<Contact>>(`/contacts/${id}`, fields, { context: new HttpContext().set(NO_INTERCEPTOR_ERROR, true) });
    }

    createContact(contact: Contact) {
        return this.http.post<Readonly<Contact>>('/contacts', createContactForRequest(contact), { context: new HttpContext().set(NO_INTERCEPTOR_ERROR, true) });
    }

    search(query: SearchQueryBody) {
        return this.http.post<Readonly<CollectionOfContacts>>('/contacts/search', query).pipe(
            map((result) => ({
                ...result,
                ...(result.metadata ? { metadata: formatMetadatasContact(result.metadata) } : {}),
            })),
        );
    }

    getSearchMetaData(refreshCache: boolean = false) {
        if (refreshCache) {
            // eslint-disable-next-line @typescript-eslint/unbound-method
            const moizedFn = this.getSearchMetaDataRaw as Moized;
            moizedFn.clear();
        }

        return this.getSearchMetaDataRaw();
    }

    deleteContacts(ids: number[]): Observable<boolean> {
        if (!ids.length) {
            return of(false);
        }
        const [first, ...rest] = ids;
        return this.deleteContact(first)
            .pipe(
                switchMap(() => {
                    if (rest.length) {
                        return this.deleteContacts(rest);
                    }
                    return of(true);
                }),
            );
    }

    @Memoize({
        isObservable: true,
    })
    private getSearchMetaDataRaw() {
        return this.http.get<Readonly<SearchMetaData>>('/contacts/search/metadata').pipe(
            map((metadata) => formatMetadatasContact(metadata)),
        );
    }
}
