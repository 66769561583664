import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Actions,
    ofActionSuccessful,
    Select,
    Store,
} from '@ngxs/store';
import {
    map,
    Observable,
} from 'rxjs';

import { MemberRegisterActivationModel } from '~/app/shared/types/api/member-register-activation-model.type';
import { AuthenticationTokens } from '~/app/shared/types/authentication/authentication-tokens.type';
import { LoginRequestData } from '~/app/shared/types/authentication/login-request-data.type';
import { PasswordForgetRequestData } from '~/app/shared/types/authentication/password-forget-request-data.type';
import { PasswordResetRequestData } from '~/app/shared/types/authentication/password-reset-request-data.type';
import { RefreshTokenRequestData } from '~/app/shared/types/authentication/refresh-token-request-data.type';
import { Company } from '~/app/shared/types/company.type';
import { User } from '~/app/shared/types/user/user.type';

import {
    ActivateAccountAction,
    ChangeLanguageAction,
    LoginAction,
    LogoutAction,
    PasswordForgetAction,
    PasswordForgetErrorAction,
    PasswordResetAction,
    PasswordResetErrorAction,
    RefreshChallengeTokenAction,
    RefreshTokenAction,
    RefreshUserAction,
    ResetChallengeAction,
    SetTokensAction,
    SignupAction,
    UpdateIsChallengeRenewingAction,
    UpdateIsConsentAcceptedAction,
    UpdateLogosAction,
    UpdateLogosArgs,
    UpdateTenantAction,
    UpdateUserAction,
    WhoamiAction,
} from './authentication.actions';
import { AuthenticationState } from './authentication.state';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationFacade {
    @Select(AuthenticationState.user)
    public user$!: Observable<User | undefined>;

    @Select(AuthenticationState.userLanguage)
    public userLanguage$!: Observable<string>;

    @Select(AuthenticationState.userCompany)
    public userCompany$!: Observable<Company | undefined>;

    @Select(AuthenticationState.token)
    public token$!: Observable<string | null | undefined>;

    @Select(AuthenticationState.challengeToken)
    public challengeToken$!: Observable<string | null | undefined>;

    @Select(AuthenticationState.loginError)
    public loginError$!: Observable<HttpErrorResponse | null>;

    @Select(AuthenticationState.isAuthenticated)
    public isAuthenticated$!: Observable<boolean>;

    @Select(AuthenticationState.isChallengeRenewing)
    public isChallengeRenewing$!: Observable<boolean | null>;

    @Select(AuthenticationState.userIsConsentAccepted)
    public userIsConsentAccepted$!: Observable<boolean>;

    constructor(
        private store: Store,
        private actions$: Actions,
    ) {}

    public onLogin() {
        return this.actions$.pipe(
            ofActionSuccessful(LoginAction),
        );
    }

    public onLogout() {
        return this.actions$.pipe(
            ofActionSuccessful(LogoutAction),
        );
    }

    public onPasswordReset() {
        return this.actions$.pipe(
            ofActionSuccessful(PasswordResetAction),
        );
    }

    public onPasswordForgetError(): Observable<HttpErrorResponse> {
        return this.actions$.pipe(
            ofActionSuccessful(PasswordForgetErrorAction),
            map((action: PasswordForgetErrorAction) => action.error),
        );
    }

    public onPasswordResetError(): Observable<HttpErrorResponse> {
        return this.actions$.pipe(
            ofActionSuccessful(PasswordResetErrorAction),
            map((action: PasswordResetErrorAction) => action.error),
        );
    }

    public onPasswordResetSuccess(): Observable<boolean> {
        return this.actions$.pipe(
            ofActionSuccessful(PasswordResetAction),
            map(() => true),
        );
    }

    public onPasswordForgetSuccess(): Observable<boolean> {
        return this.actions$.pipe(
            ofActionSuccessful(PasswordForgetAction),
            map(() => true),
        );
    }

    public onChangeLanguageSuccess(): Observable<boolean> {
        return this.actions$.pipe(
            ofActionSuccessful(ChangeLanguageAction),
            map(() => true),
        );
    }

    public onRefreshTokenSuccess(): Observable<boolean> {
        return this.actions$.pipe(
            ofActionSuccessful(RefreshTokenAction),
            map(() => true),
        );
    }

    public onUpdateLogosSuccess(): Observable<boolean> {
        return this.actions$.pipe(
            ofActionSuccessful(UpdateLogosAction),
            map(() => true),
        );
    }

    public onSignupSuccess(): Observable<boolean> {
        return this.actions$.pipe(
            ofActionSuccessful(SignupAction),
            map(() => true),
        );
    }

    public onActivateAccountSuccess(): Observable<boolean> {
        return this.actions$.pipe(
            ofActionSuccessful(ActivateAccountAction),
            map(() => true),
        );
    }

    public activateAccount(data: MemberRegisterActivationModel) {
        return this.store.dispatch(new ActivateAccountAction(data));
    }

    public changeLanguage(language: string) {
        return this.store.dispatch(new ChangeLanguageAction(language));
    }

    public login(data: LoginRequestData) {
        return this.store.dispatch(new LoginAction(data));
    }

    public logout() {
        return this.store.dispatch(new LogoutAction());
    }

    public passwordReset(data: PasswordResetRequestData) {
        return this.store.dispatch(new PasswordResetAction(data));
    }

    public passwordForget(data: PasswordForgetRequestData) {
        return this.store.dispatch(new PasswordForgetAction(data));
    }

    public refreshToken(data: RefreshTokenRequestData) {
        return this.store.dispatch(new RefreshTokenAction(data));
    }

    public updateLogos(logos: UpdateLogosArgs) {
        return this.store.dispatch(new UpdateLogosAction(logos));
    }

    public setAuthenticationTokens(authenticationTokens: AuthenticationTokens) {
        this.store.dispatch(new SetTokensAction(authenticationTokens));
    }

    public signup(email: string): Observable<unknown> {
        return this.store.dispatch(new SignupAction(email));
    }

    public whoami() {
        return this.store.dispatch(new WhoamiAction());
    }

    public refreshChallengeToken(challenge: string) {
        return this.store.dispatch(new RefreshChallengeTokenAction(challenge));
    }

    public resetChallenge(): Observable<unknown> {
        return this.store.dispatch(new ResetChallengeAction());
    }

    public updateUser(user: User): Observable<unknown> {
        return this.store.dispatch(new UpdateUserAction(user));
    }

    public refreshUser(): Observable<unknown> {
        return this.store.dispatch(new RefreshUserAction());
    }

    public updateIsChallengeUpdateLocked(isChallengeRenewing: boolean): Observable<unknown> {
        return this.store.dispatch(new UpdateIsChallengeRenewingAction(isChallengeRenewing));
    }

    public getUserSnapshot(): User | undefined {
        return this.store.selectSnapshot(AuthenticationState.user);
    }

    public getUserCurrencySnapshot(): string | undefined {
        return this.store.selectSnapshot(AuthenticationState.userCurrency);
    }

    public getUserLanguageSnapshot(): string {
        return this.store.selectSnapshot(AuthenticationState.userLanguage);
    }

    public getUserIsConsentAcceptedSnapshot(): boolean | undefined {
        return this.store.selectSnapshot(AuthenticationState.userIsConsentAccepted);
    }

    public getUserNameSnapshot(): string | undefined {
        return this.store.selectSnapshot(AuthenticationState.userName);
    }

    public getTokenSnapshot(): string | null | undefined {
        return this.store.selectSnapshot(AuthenticationState.token);
    }

    public getChallengeTokenSnapshot(): string | null | undefined {
        return this.store.selectSnapshot(AuthenticationState.challengeToken);
    }

    public getRefreshTokenSnapshot(): string | null | undefined {
        return this.store.selectSnapshot(AuthenticationState.refreshToken);
    }

    public getTenantSnapshot(): string | null | undefined {
        return this.store.selectSnapshot(AuthenticationState.tenant);
    }

    public getInstanceSnapshot(): string | null | undefined {
        return this.store.selectSnapshot(AuthenticationState.instance);
    }

    public getIsAuthenticatedSnapshot(): boolean {
        return this.store.selectSnapshot(AuthenticationState.isAuthenticated);
    }

    public getIsChallengeRenewingSnapshot(): boolean | null | undefined {
        return this.store.selectSnapshot(AuthenticationState.isChallengeRenewing);
    }


    public getFreeTrialUsedSnapshot(): boolean | undefined {
        return this.store.selectSnapshot(AuthenticationState.userFreeTrialUsed);
    }

    public updateIsConsentAccepted(IsConsentAccepted: boolean) {
        return this.store.dispatch(new UpdateIsConsentAcceptedAction(IsConsentAccepted));
    }

    public forceTenantDuringInit() : Observable<any> | string {
        const accountServicerIdParam = new URL(window.location.href).searchParams.get('accountServicerId') ?? '';
        if (accountServicerIdParam.length > 0) {
            return this.store.dispatch(new UpdateTenantAction(accountServicerIdParam));
        }
        return accountServicerIdParam;
    }
}
