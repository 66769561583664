export enum RESUME_FEATURES {
    'ADD_CATEGORY_IN_SIMULATION_SCENARIO' = 'ADD_CATEGORY_IN_SIMULATION_SCENARIO',
    'ADD_FUND_IN_SIMULATION_SCENARIO' = 'ADD_FUND_IN_SIMULATION_SCENARIO',
    'ADD_FUND_SEARCH_LIST' = 'ADD_FUND_SEARCH_LIST',
    'ADD_FUND_TO_EXISTING_PORTFOLIO' = 'ADD_FUND_TO_EXISTING_PORTFOLIO',
    'ADD_FUND_TO_EXISTING_SEARCH_LIST' = 'ADD_FUND_TO_EXISTING_SEARCH_LIST',
    'ADD_MARKET_EVENT_IN_SIMULATION_SCENARIO' = 'ADD_MARKET_EVENT_IN_SIMULATION_SCENARIO',
    'ADD_PORTFOLIO_IN_SIMULATION_HISTORICAL' = 'ADD_PORTFOLIO_IN_SIMULATION_HISTORICAL',
    'ADD_PORTFOLIO_IN_SIMULATION_PROJECTION' = 'ADD_PORTFOLIO_IN_SIMULATION_PROJECTION',
    'ADD_PORTFOLIO_IN_SIMULATION_SCENARIO' = 'ADD_PORTFOLIO_IN_SIMULATION_SCENARIO',
    'ADD_WORKSPACE' = 'ADD_WORKSPACE',
    'CONTACT_MODULE' = 'CONTACT_MODULE',
    'FUND_MEDIA' = 'FUND_MEDIA',
    'FUND_OTHER_SHARES' = 'FUND_OTHER_SHARES',
    'FUND_SEARCH_ADVANCED_DISPLAY' = 'FUND_SEARCH_ADVANCED_DISPLAY',
    'FUND_SEARCH_EXCEL_EXPORT' = 'FUND_SEARCH_EXCEL_EXPORT',
    'FUND_SEARCH_UNIVERSE' = 'FUND_SEARCH_UNIVERSE',
    'FUND_SHEET_COMPARE_MY_LISTS' = 'FUND_SHEET_COMPARE_MY_LISTS',
    'FUND_SHEET_COMPARE_OTHER_SHARES' = 'FUND_SHEET_COMPARE_OTHER_SHARES',
    'FUND_SHEET_COMPARE_TOP_SIMILARS' = 'FUND_SHEET_COMPARE_TOP_SIMILARS',
    'FUND_SHEET_LIFE_INSURANCE_ELIGIBILITY' = 'FUND_SHEET_LIFE_INSURANCE_ELIGIBILITY',
    'FUND_SHEET_MARKET_CYCLE_ANALYSIS' = 'FUND_SHEET_MARKET_CYCLE_ANALYSIS',
    'FUND_SHEET_REPORTING_DATE' = 'FUND_SHEET_REPORTING_DATE',
    'LAUNCH_SIMULATION_HISTORICAL' = 'LAUNCH_SIMULATION_HISTORICAL',
    'LAUNCH_SIMULATION_PROJECTION' = 'LAUNCH_SIMULATION_PROJECTION',
    'LAUNCH_SIMULATION_SCENARIO' = 'LAUNCH_SIMULATION_SCENARIO',
    'PORTFOLIO_ADD_CONTACT' = 'PORTFOLIO_ADD_CONTACT',
    'PORTFOLIO_ADD_FUND' = 'PORTFOLIO_ADD_FUND',
    'PORTFOLIO_ALERT' = 'PORTFOLIO_ALERT',
    'PORTFOLIO_CREATE_MODEL' = 'PORTFOLIO_CREATE_MODEL',
    'PORTFOLIO_CREATE_REAL' = 'PORTFOLIO_CREATE_REAL',
    'PORTFOLIO_DOCUMENTS' = 'PORTFOLIO_DOCUMENTS',
    'PORTFOLIO_DUPLICATE' = 'PORTFOLIO_DUPLICATE',
    'PORTFOLIO_EQUIPONDERATION_ALLOCATION_MANAGE' = 'PORTFOLIO_EQUIPONDERATION_ALLOCATION_MANAGE',
    'PORTFOLIO_MODULE' = 'PORTFOLIO_MODULE',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_MANAGE' = 'PORTFOLIO_OPTIMIZATION_ALLOCATION_MANAGE',
    'PORTFOLIO_REBALANCING' = 'PORTFOLIO_REBALANCING',
    'PORTFOLIO_REPORTING_PDF' = 'PORTFOLIO_REPORTING_PDF',
    'PORTFOLIO_TOP_FUND_MANAGE' = 'PORTFOLIO_TOP_FUND_MANAGE',
    'PORTFOLIO_VALUATION_PROCESS' = 'PORTFOLIO_VALUATION_PROCESS',
    'PROFILE_LOGO_MANAGE' = 'PROFILE_LOGO_MANAGE',
    'SELECT_SUBSEGMENTATION' = 'SELECT_SUBSEGMENTATION',
    'SELECT_WORKSPACE' = 'SELECT_WORKSPACE',
    'SET_BENCHMARK' = 'SET_BENCHMARK',
    'SIMULATION_ADD_TOP_SIMILAR' = 'SIMULATION_ADD_TOP_SIMILAR',
    'SIMULATION_HISTORICAL_ADD_CATEGORY' = 'SIMULATION_HISTORICAL_ADD_CATEGORY',
    'SIMULATION_HISTORICAL_ADD_FUND' = 'SIMULATION_HISTORICAL_ADD_FUND',
    'SIMULATION_PROJECTION_CHALLENGE_EVENT' = 'SIMULATION_PROJECTION_CHALLENGE_EVENT',
    'SIMULATION_REPORTING_EXPORT_PDF' = 'SIMULATION_REPORTING_EXPORT_PDF',
    'SIMULATION_SCENARIO_CREATE_SCENARIO' = 'SIMULATION_SCENARIO_CREATE_SCENARIO',
    'SIMULATION_SCENARIO_DELETE_SCENARIO' = 'SIMULATION_SCENARIO_DELETE_SCENARIO',
    'SIMULATION_SCENARIO_EDIT' = 'SIMULATION_SCENARIO_EDIT',
    'SIMULATION_SCENARIO_EDIT_SCENARIO' = 'SIMULATION_SCENARIO_EDIT_SCENARIO',
    'SIMULATION_SCENARIO_SELECT_SCENARIO' = 'SIMULATION_SCENARIO_SELECT_SCENARIO',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_ACCESS' = 'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_ACCESS',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_VIEW'= 'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_VIEW',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_EDIT' = 'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_EDIT',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_REMOVE' = 'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_REMOVE',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_ADD' = 'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_ADD',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_RESTORE' = 'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_RESTORE',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_SAVE' = 'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_SAVE',
    'PORTFOLIO_OPTIMIZATION_SEARCH_TOP_FUNDS_BY_CATEGORIES' = 'PORTFOLIO_OPTIMIZATION_SEARCH_TOP_FUNDS_BY_CATEGORIES',
}
