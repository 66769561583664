/* eslint-disable max-classes-per-file */

import { ConsentFlowStates } from '~/app/shared/enums/consent/consent-flow-state.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { ComparisonListModel } from '~/app/shared/types/api/comparison-list-model.type';
import { ReferenceModel } from '~/app/shared/types/api/reference-model.type';
import { Operation } from '~/app/shared/types/operation.type';

export class AddEntitiesToComparisonListAction {
    static readonly type = '[Application] Add entities to comparison list';

    constructor(
        public id: number,
        public entities: ReferenceModel[],
    ) {}
}

export class CreateComparisonListAction {
    static readonly type = '[Application] Create comparison list';

    constructor(
        public name: string,
        public entities: ReferenceModel[] = [],
    ) {}
}

export class CreateComparisonListSuccessAction {
    static readonly type = '[Application] Create comparison list success';

    constructor(
        public item: ComparisonListModel,
    ) {}
}

export class GetAlertsAction {
    static readonly type = '[Application] Get alerts';
}

export class GetAllocationStylesAction {
    static readonly type = '[Application] Get allocation styles';
}

export class GetComparisonListsAction {
    static readonly type = '[Application] Get comparison lists';
}

export class GetContextsAction {
    static readonly type = '[Application] Get contexts';
}

export class GetCurrenciesAction {
    static readonly type = '[Application] Get currencies';

    constructor(public isDefault: boolean = false) {}
}

export class GetDocumentCategoriesAction {
    static readonly type = '[Application] Get document categories';

    constructor(public module: 'FUND' | 'PORTFOLIO' = 'FUND') {}
}

export class GetForexAction {
    static readonly type = '[Application] Get forex';

    constructor(public code: string) {}
}

export class GetFrequenciesAction {
    static readonly type = '[Application] Get frequencies';
}

export class GetGeographiesAction {
    static readonly type = '[Application] Get geographies';
}

export class GetMarketEventsAction {
    static readonly type = '[Application] Get MarketEvents';
}

export class GetMarketCyclesAction {
    static readonly type = '[Application] Get MarketCycles';
}

export class GetPeriodsAction {
    static readonly type = '[Application] Get periods';
}

export class GetRiskProfilesAction {
    static readonly type = '[Application] Get RiskProfiles';
}

export class GetSrriVolatilitiesAction {
    static readonly type = '[Application] Get SrriVolatilities';
}

export class GetSavedAllocationConstraintsAction {
    static readonly type = '[Application] Get constraints allocation saved';
}

export class GetVideoCategoriesAction {
    static readonly type = '[Application] Get video categories';

    constructor(public module: 'FUND' | 'PORTFOLIO' = 'FUND') {}
}

export class ReplaceComparisonListEntitiesAction {
    static readonly type = '[Application] Replace comparison list entities';

    constructor(
        public id: number,
        public entities: ReferenceModel[],
    ) {}
}

export class RenameComparisonListAction {
    static readonly type = '[Application] Rename comparison list';

    constructor(
        public id: number,
        public name: string,
    ) {}
}

export class DeleteComparisonListAction {
    static readonly type = '[Application] Delete comparison list';

    constructor(
        public id: number,
    ) {}
}

export class UpdateDisplayLeftColumnPortfolioAction {
    static readonly type = '[Application] Update boolean to display left column of portfolio';

    constructor(public displayLeftColumn: boolean) {}
}

export class UpdateDisplayLeftColumnShareAction {
    static readonly type = '[Application] Update boolean to display left column of share';

    constructor(public displayLeftColumn: boolean) {}
}

export class UpdateSimulationTopFundsLatestIndicatorAction {
    static readonly type = '[Application] Update Simulation Top Funds Latest Indicator';

    constructor(public indicator: { code: string, period: Period} | null) {}
}

export class AddOperationAction<T> {
    static readonly type = '[Application] Add operation';

    constructor(public id: string, public operation: Operation<T>) {}
}

export class DeleteOperationAction {
    static readonly type = '[Application] Delete operation';

    constructor(public id: string) {}
}

export class GetMenusAction {
    static readonly type = '[Application] Get menus';
}

export class UpdateLastCheckSessionDateAction {
    static readonly type = '[Application] Update last checking session date';

    constructor(public lastCheckSessionDate: string | null) {}
}

export class UpdateIsFreemiumOnboardingVisibleAction {
    static readonly type = '[First Visit Freemium] Freemium onboarding flag';

    constructor(public isFreemiumOnboardingVisible: boolean) {}
}

export class HasPortfoliosAction {
    static readonly type = '[Application] User has portfolios ?';

    constructor(public hasPortfolios: boolean | undefined) {}
}

export class UpdateIsIntroductionEnhancementSkippedAction {
    static readonly type = '[Application] Update portfolio enhancement introduction skipped';

    constructor(
        public isSkipped: boolean,
    ) { }
}

export class UpdateIsIntroductionOptimalCreationSkippedAction {
    static readonly type = '[Application] Update optimal creation introduction skipped';

    constructor(
        public isSkipped: boolean,
    ) { }
}

export class ConsentAction {
    public static readonly type = '[Consent] User gave consent';

    constructor(public consentState: ConsentFlowStates) {}
}
export class UpdateAccountServicerIdAction {
    static readonly type = '[Application TAPICO] Update activeServicerId';

    constructor(public accountServicerId: string | undefined) {}
}
