export enum SIMULATION_SCENARIO_PERMISSIONS {
    'SIMULATION_SCENARIO_ALLOCATION_INCLUDE' = 'simulation_scenario:allocation:include', // 'Permission to use allocations as elements of the simulation'
    'SIMULATION_SCENARIO_BENCHMARK_DEFINE' = 'simulation_scenario:benchmark:define', // 'Permission to use an elements of the simulation as a benchmark'
    'SIMULATION_SCENARIO_CATEGORY_INCLUDE' = 'simulation_scenario:category:include', // 'Permission to use categories as elements of the simulation'
    'SIMULATION_SCENARIO_DEFAULT_SCENARIO_READ' = 'simulation_scenario:default_scenario:read', // 'Permission to read the default envest board scenarios'
    'SIMULATION_SCENARIO_FUND_INCLUDE' = 'simulation_scenario:fund:include', // 'Permission to use funds as elements of the simulation'
    'SIMULATION_SCENARIO_MANAGE' = 'simulation_scenario:scenario:manage', // 'Permission to create, update, delete custom scenarios'
    'SIMULATION_SCENARIO_PORTFOLIO_INCLUDE' = 'simulation_scenario:portfolio:include', // 'Permission to use portfolios as elements of the simulation'
    'SIMULATION_SCENARIO_PROCESS' = 'simulation_scenario:simulation:process', // 'Permission to process and visualize the simulation'
    'SIMULATION_SCENARIO_TOP_SIMILAR_ACCESS' = 'simulation_scenario:top_similar:access',
}
