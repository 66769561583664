/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from '@angular/common/http';

import { MemberRegisterActivationModel } from '~/app/shared/types/api/member-register-activation-model.type';
import { AuthenticationTokens } from '~/app/shared/types/authentication/authentication-tokens.type';
import { LoginRequestData } from '~/app/shared/types/authentication/login-request-data.type';
import { PasswordForgetRequestData } from '~/app/shared/types/authentication/password-forget-request-data.type';
import { PasswordResetRequestData } from '~/app/shared/types/authentication/password-reset-request-data.type';
import { RefreshTokenRequestData } from '~/app/shared/types/authentication/refresh-token-request-data.type';
import { User } from '~/app/shared/types/user/user.type';

export type UpdateLogosArgs = {
    logoFile?: File | null,
    squareLogoFile?: File | null,
}

export class ActivateAccountAction {
    public static readonly type = '[Authentication] Activate Account';

    public constructor(public data: MemberRegisterActivationModel) {}
}

export class ChangeLanguageAction {
    public static readonly type = '[Authentication] Change Language';

    public constructor(public language: string) { }
}

export class LoginAction {
    public static readonly type = '[Authentication] Login';

    public constructor(public payload: LoginRequestData) { }
}

export class PasswordForgetAction {
    public static readonly type = '[Authentication] Password Forget';

    public constructor(public payload: PasswordForgetRequestData) { }
}

export class PasswordForgetErrorAction {
    public static readonly type = '[Authentication] Password Forget Error';

    public constructor(public error: HttpErrorResponse) { }
}


export class PasswordResetAction {
    public static readonly type = '[Authentication] Password Reset';

    public constructor(public payload: PasswordResetRequestData) { }
}

export class PasswordResetErrorAction {
    public static readonly type = '[Authentication] Password Reset Error';

    public constructor(public error: HttpErrorResponse) { }
}

export class RefreshTokenAction {
    public static readonly type = '[Authentication] Refresh Token';

    public constructor(public payload: RefreshTokenRequestData) { }
}

export class LogoutAction {
    public static readonly type = '[Authentication] Logout';
}

export class SetTokensAction {
    public static readonly type = '[Authentication] Set Tokens';

    public constructor(public authenticationTokens: AuthenticationTokens) { }
}

export class SignupAction {
    public static readonly type = '[Authentication] Signup';

    public constructor(public email: string) { }
}

export class UpdateLogosAction {
    public static readonly type = '[Authentication] Update logos';

    public constructor(public logos: UpdateLogosArgs) { }
}
export class UpdateTenantAction {
    public static readonly type = '[Authentication] Update tenant';

    public constructor(public tenant: string) { }
}

export class WhoamiAction {
    public static readonly type = '[Authentication] Who Am I';
}

export class RefreshUserAction {
    public static readonly type = '[Authentication] Refresh user';
}

export class RefreshChallengeTokenAction {
    static readonly type = '[Challenge captcha] Refresh challenge token';

    constructor(public challenge: string) {}
}

export class ResetChallengeAction {
    static readonly type = '[Challenge captcha] Reset challenge after expiration';
}
export class UpdateIsChallengeRenewingAction {
    static readonly type = '[Challenge captcha] Lock Challenge';

    public constructor(public isChallengeRenewing: boolean) { }
}

export class UpdateUserAction {
    static readonly type = '[Authentication] Update user info';

    public constructor(public user: User) { }
}

export class UpdateIsConsentAcceptedAction {
    public static readonly type = '[Authentication] Update user contentment to link external account with envestboard';

    public constructor(public IsConsentAccepted: boolean) { }
}
