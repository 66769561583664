export enum GroupCompositionViewMode {
    ASSET = 'ASSET',
    GEOGRAPHY = 'GEOGRAPHY',
    CATEGORY = 'CATEGORY',
    SUBCATEGORY = 'SUBCATEGORY',
    COUNTRY_TYPE = 'COUNTRY_TYPE',
    SHARE = 'SHARE',
    CURRENCY = 'CURRENCY',
    SUSTAINABLE_FINANCE_LABEL = 'SUSTAINABLE_FINANCE_LABEL',
    SUSTAINABLE_FINANCE_SFDR = 'SUSTAINABLE_FINANCE_SFDR',
}
