export enum FUND_SHEET_PERMISSIONS {
    'FUND_SHEET_LEGAL_DOCUMENT_ACCESS' = 'fund_sheet:legal_document:access', // 'Permission to access legal documents'
    'FUND_SHEET_LIFE_INSURANCE_ACCESS' = 'fund_sheet:life_insurance:access', // 'Permission to access life insurance eligibility'
    'FUND_SHEET_MANAGEMENT_DOCUMENT_ACCESS' = 'fund_sheet:management_document:access', // 'Permission to access management documents'
    'FUND_SHEET_MARKET_ANALYSIS_DOCUMENT_ACCESS' = 'fund_sheet:market_analysis_document:access', // 'Permission to access market analysis documents'
    'FUND_SHEET_MARKET_CYCLE_ANALYSIS_ACCESS' = 'fund_sheet:market_cycle_analysis:access', // 'Permission to access the market cycle analysis'
    'FUND_SHEET_OTHER_SHARES_ACCESS' = 'fund_sheet:other_shares:access', // 'Permission to access other shares'
    'FUND_SHEET_REPORTING_DATE_MANAGEMENT' = 'fund_sheet:reporting_date:manage', // 'Permission to manage the date of the reporting to a prior date'
    'FUND_SHEET_REPORTING_DOCUMENT_ACCESS' = 'fund_sheet:reporting_document:access', // 'Permission to access reportings documents'
    'FUND_SHEET_TOP_SIMILAR_ACCESS' = 'fund_sheet:top_similar:access', // 'Permission to access top similar'
    'FUND_SHEET_VIDEO_ACCESS' = 'fund_sheet:video:access', // 'Permission to access funds videos'
}
