import { HttpClient } from '@angular/common/http';
import {
    inject,
    Injectable,
} from '@angular/core';
import {
    finalize,
    map,
    Observable,
} from 'rxjs';
import {
    share,
} from 'rxjs/operators';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { MemberRegisterActivationModel } from '~/app/shared/types/api/member-register-activation-model.type';
import { MemberRegisterModel } from '~/app/shared/types/api/member-register-model.type';
import { LoginRequestData } from '~/app/shared/types/authentication/login-request-data.type';
import { LoginSuccessResponseExtended } from '~/app/shared/types/authentication/login-success-response-extended.type';
import { PasswordForgetRequestData } from '~/app/shared/types/authentication/password-forget-request-data.type';
import { PasswordForgetSuccessResponse } from '~/app/shared/types/authentication/password-forget-success-response.type';
import { PasswordResetRequestData } from '~/app/shared/types/authentication/password-reset-request-data.type';
import { PasswordResetSuccessResponse } from '~/app/shared/types/authentication/password-reset-success-response.type';
import { RefreshTokenRequestData } from '~/app/shared/types/authentication/refresh-token-request-data.type';
import { RefreshTokenSuccessResponse } from '~/app/shared/types/authentication/refresh-token-success-response.type';
import { SalesRecallRequest } from '~/app/shared/types/authentication/sales-recall-request.type';
import { Company } from '~/app/shared/types/company.type';
import { User } from '~/app/shared/types/user/user.type';


@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public pendingRefreshTokenRequest$: Observable<RefreshTokenSuccessResponse> | null = null;

    private http = inject(HttpClient);

    register(data: MemberRegisterModel) {
        return this.http.post('/members/registration', data);
    }

    activateAccount(data: MemberRegisterActivationModel) {
        return this.http.post<LoginSuccessResponseExtended>('/members/activation',
            data,
            {
                observe: 'response',
            }).pipe(
            map((resp) => ({
                ...resp.body,
                tenant: resp.headers.get('tenant'),
                instance: resp.headers.get('instance'),
            } as LoginSuccessResponseExtended)),
        );
    }

    login(data: LoginRequestData) {
        return this.http.post<LoginSuccessResponseExtended>('/members/authentication',
            data,
            {
                observe: 'response',
            }).pipe(
            map((resp) => ({
                ...resp.body,
                tenant: resp.headers.get('tenant'),
                instance: resp.headers.get('instance'),
            } as LoginSuccessResponseExtended)),
        );
    }

    passwordForget(data: PasswordForgetRequestData) {
        return this.http.delete<PasswordForgetSuccessResponse>('/members/password', { body: data });
    }

    passwordReset(data: PasswordResetRequestData) {
        return this.http.post<PasswordResetSuccessResponse>('/members/password', data);
    }

    refreshToken(data: RefreshTokenRequestData) {
        if (this.pendingRefreshTokenRequest$) {
            return this.pendingRefreshTokenRequest$;
        }

        this.pendingRefreshTokenRequest$ = this.http.post<RefreshTokenSuccessResponse>('/members/refreshToken', data)
            .pipe(
                share(),
                finalize(() => {
                    this.pendingRefreshTokenRequest$ = null;
                }),
            );

        return this.pendingRefreshTokenRequest$;
    }

    userCompany(userId: number | null | undefined) {
        return this.userCompanyRaw(userId);
    }

    user() {
        return this.http.get<User>('/members/me');
    }

    recallMe(data: SalesRecallRequest) {
        return this.http.post('/members/me/sales-recall', data);
    }

    @Memoize({
        isObservable: true,
    })
    private userCompanyRaw(_userId: number | null | undefined) {
        return this.http.get<Company>('/members/me/company');
    }
}
