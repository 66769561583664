import { HttpContextToken } from '@angular/common/http';

import { GroupCompositionViewMode } from '~/app/shared/enums/group-composition-view-mode.enum';
import { Option } from '~/app/shared/types/option.type';

export const ACCEPT_UPLOAD_FILES = [
    '.csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];


export const DEFAULT_PERIODS = [
    {
        code: 'SIC',
        name: 'common.periods.SIC.label',
    },
    {
        code: 'YTD',
        name: 'common.periods.YTD.label',
    },
    {
        code: '03M',
        name: 'common.periods.03M.label',
    },
    {
        code: '06M',
        name: 'common.periods.06M.label',
    },
    {
        code: '01Y',
        name: 'common.periods.01Y.label',
    },
    {
        code: '03Y',
        name: 'common.periods.03Y.label',
    },
    {
        code: '05Y',
        name: 'common.periods.05Y.label',
    },
];

export const COMPOSITION_VIEW_MODE_OPTION: Option<string>[] = [
    {
        id: GroupCompositionViewMode.ASSET, label: 'portfolios_details.allocation.menu.display.assets',
    },
    {
        id: GroupCompositionViewMode.GEOGRAPHY, label: 'portfolios_details.allocation.menu.display.geographies',
    },
    {
        id: GroupCompositionViewMode.CATEGORY, label: 'portfolios_details.allocation.menu.display.categories',
    },
    {
        id: GroupCompositionViewMode.SUBCATEGORY, label: 'portfolios_details.allocation.menu.display.subcategories',
    },
    {
        id: GroupCompositionViewMode.COUNTRY_TYPE, label: 'portfolios_details.allocation.menu.display.country_type',
    },
    {
        id: GroupCompositionViewMode.SHARE, label: 'portfolios_details.allocation.menu.display.shares',
    },
    {
        id: GroupCompositionViewMode.CURRENCY, label: 'portfolios_details.allocation.menu.display.currencies',
    },
    {
        id: GroupCompositionViewMode.SUSTAINABLE_FINANCE_LABEL, label: 'portfolios_details.allocation.menu.display.sustainable_finance_label',
    },
    {
        id: GroupCompositionViewMode.SUSTAINABLE_FINANCE_SFDR, label: 'portfolios_details.allocation.menu.display.sustainable_finance_sfdr',
    },
];

export const LANGUAGES = [
    {
        code: 'fr',
        label: 'Français',
        icon: 'fr',
    },
    {
        code: 'en',
        label: 'English',
        icon: 'us',
    },
];

export const NO_INTERCEPTOR_ERROR = new HttpContextToken(() => false);
export const PORTFOLIO_BENCHMARK_PREFIX: string = 'ptf-bench';
