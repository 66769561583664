export enum PORTFOLIO_PERMISSIONS {
    'PORTFOLIO_MODULE_ACCESS' = 'portfolio:module:access', // 'Permission to access to the portfolio module'
    'PORTFOLIO_ALERT_MANAGE' = 'portfolio:alert:manage', // 'Permission to manage visualize and manage alerts'
    'PORTFOLIO_MODEL_MANAGE' = 'portfolio:model:manage', // 'Permission to manage model portfolio'
    'PORTFOLIO_REAL_MANAGE' = 'portfolio:real:manage', // 'Permission to manage real portfolio'
    'PORTFOLIO_REBALANCING_MANAGE' = 'portfolio:rebalancing:manage', // 'Permission to manage automatic rebalancing'
    'PORTFOLIO_REPORTING_PDF_ACCESS' = 'portfolio:reporting_pdf:access', // 'Permission to print pdf of the portfolio reporting'
    'PORTFOLIO_VALUATION_PROCESS' = 'portfolio:valuation:process', // 'Permission to have valuation of model and real portfolio, only back for the moment'
    'PORTFOLIO_DOCUMENTS_MANAGE' = 'portfolio:documents:manage', // 'Permission to manage portfolio documents'
    'PORTFOLIO_TOP_FUND_MANAGE' = 'portfolio:top_fund:manage', // 'Permission to replace a fund by a top one of the same cat or scat'
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_MANAGE' = 'portfolio:optimization_allocation:manage', // 'Permission to allow ptf optimisation'
    'PORTFOLIO_OPTIMIZATION_CONSTRAINT_MANAGE' = 'portfolio:optimization_constraint:manage', // 'Permission to allow add and edit optimisation constraints, not yet used'
    'PORTFOLIO_EQUIPONDERATION_ALLOCATION_MANAGE' = 'portfolio:equiponderation_allocation:manage', // 'Permission to equiponderate weights of a portfolio'
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_VIEW'= 'portfolio:optimization_allocation:default_constraint:view',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_ACCESS'= 'portfolio:optimization_allocation:default_constraint:acces',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_REMOVE'= 'portfolio:optimization_allocation:default_constraint:remove',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_EDIT'= 'portfolio:optimization_allocation:default_constraint:edit',
    'PORTFOLIO_OPTIMIZATION_ALLOCATION_CONSTRAINT_MANAGE'= 'portfolio:optimization_constraint:manage',
}
