// eslint-disable-next-line max-classes-per-file
export class AddWorkspaceAction {
    static readonly type = '[ContactsWorkspace] Add workspace';

    constructor(public payload: { label?: string }) { }
}

export class ChangeActiveWorkspaceAction {
    static readonly type = '[ContactsWorkspace] Change active workspace';

    constructor(public key: string) { }
}

export class CloseWorkspaceAction {
    static readonly type = '[ContactsWorkspace] Close workspace';

    constructor(public key: string) { }
}

export class ResetContactsWorkspaceAction {
    static readonly type = '[ContactsWorkspace] Reset workspace';
}
