import { ContactRequest } from '~/app/shared/types/contacts/contact-request.type';
import { Contact } from '~/app/shared/types/contacts/contact.type';

export function createContactForRequest(contact: Contact) : ContactRequest {
    return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        srri: contact.srri,
        phone: contact.phone,
        profession: contact.profession,
        createdDate: contact.createdDate,
        birthdate: contact.birthdate,
        type: contact.type.id,
        language: contact.language,
        description: contact.description,
        currency: contact.currency?.code ?? 'EUR',
    };
}
