import { CONTACT_PERMISSIONS } from '~/app/shared/enums/permissions/contact-permissions.enum';
import { FUND_SEARCH_PERMISSIONS } from '~/app/shared/enums/permissions/fund-search-permissions.enum';
import { FUND_SHEET_PERMISSIONS } from '~/app/shared/enums/permissions/fund-sheet-permissions.enum';
import { PORTFOLIO_PERMISSIONS } from '~/app/shared/enums/permissions/portfolio-permissions.enum';
import { PROFILE_PERMISSIONS } from '~/app/shared/enums/permissions/profile-permissions.enum';
import { SIMULATION_HISTORICAL_PERMISSIONS } from '~/app/shared/enums/permissions/simulation-historical-permissions.enum';
import {
    SIMULATION_SAVING_PLAN_PERMISSIONS,
} from '~/app/shared/enums/permissions/simulation-projection-permissions.enum';
import { SIMULATION_SCENARIO_PERMISSIONS } from '~/app/shared/enums/permissions/simulation-scenario-permissions.enum';
import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { PermissionModel } from '~/app/shared/types/api/authorisation/permission-model.type';

export const PERMISSIONS = {
    ...CONTACT_PERMISSIONS,
    ...FUND_SEARCH_PERMISSIONS,
    ...FUND_SHEET_PERMISSIONS,
    ...PORTFOLIO_PERMISSIONS,
    ...PROFILE_PERMISSIONS,
    ...SIMULATION_HISTORICAL_PERMISSIONS,
    ...SIMULATION_SAVING_PLAN_PERMISSIONS,
    ...SIMULATION_SCENARIO_PERMISSIONS,
};

export const BRONZE_PERMISSION: PermissionModel[] = [
    {
        key: 'simulation_historical:allocation:include',
        description: 'Permission to use allocations as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_historical:category:include',
        description: 'Permission to use categories as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_historical:fund:include',
        description: 'Permission to use funds as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_historical:simulation:process',
        description: 'Permission to process and visualize the simulation',
        params: [
            {
                key: 'simulation_historical:element_max',
                description: 'Historical simulation element max',
                value: 10,
            },
            {
                key: 'simulation_historical:allocation_max',
                description: 'Historical simulation allocation max',
                value: 3,
            },
        ],
    },
    {
        key: 'simulation_historical:top_similar:access',
        description: 'Permission to use the functionnality top similar inside the simulation',
        params: [],
    },
    {
        key: 'simulation_scenario:allocation:include',
        description: 'Permission to use allocations as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_scenario:category:include',
        description: 'Permission to use categories as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_scenario:default_scenario:read',
        description: 'Permission to read the default envest board scenarios',
        params: [],
    },
    {
        key: 'simulation_scenario:fund:include',
        description: 'Permission to use funds as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_scenario:portfolio:include',
        description: 'Permission to use portfolios as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_scenario:scenario:manage',
        description: 'Permission to create, update, delete custom scenarios',
        params: [
            {
                key: 'simulation_scenario:event_max',
                description: 'Scenario simulation event max',
                value: 3,
            },
        ],
    },
    {
        key: 'simulation_scenario:simulation:process',
        description: 'Permission to process and visualize the simulation',
        params: [
            {
                key: 'simulation_scenario:element_max',
                description: 'Scenario simulation element max',
                value: 10,
            },
            {
                key: 'simulation_scenario:allocation_max',
                description: 'Scenario simulation allocation max',
                value: 3,
            },
            {
                key: 'simulation_scenario:event_max',
                description: 'Scenario simulation event max',
                value: 10,
            },
        ],
    },
    {
        key: 'simulation_scenario:top_similar:access',
        description: 'Permission to use the functionnality top similar inside the simulation',
        params: [],
    },
    {
        key: 'fund_search:display:access',
        description: 'Permission to access the display of fund data',
        params: [],
    },
    {
        key: 'fund_search:promoted:access',
        description: 'Permission to access promoted fund',
        params: [],
    },
    {
        key: 'fund_search:search_universe:manage',
        description: 'Permission to manage fund buy list',
        params: [],
    },
    {
        key: 'fund_search:sub_segment:access',
        description: 'Permission to access fund sub segmentaion',
        params: [],
    },
    {
        key: 'fund_sheet:legal_document:access',
        description: 'Permission to access legal documents',
        params: [],
    },
    {
        key: 'fund_sheet:life_insurance:access',
        description: 'Permission to access life insurance eligibility',
        params: [],
    },
    {
        key: 'fund_sheet:management_document:access',
        description: 'Permission to access management documents',
        params: [],
    },
    {
        key: 'fund_sheet:market_analysis_document:access',
        description: 'Permission to access market analysis documents',
        params: [],
    },
    {
        key: 'fund_sheet:other_shares:access',
        description: 'Permission to access other shares',
        params: [],
    },
    {
        key: 'fund_sheet:reporting_date:manage',
        description: 'Permission to manage the date of the reporting to a prior date',
        params: [],
    },
    {
        key: 'fund_sheet:reporting_document:access',
        description: 'Permission to access reportings documents',
        params: [],
    },
    {
        key: 'fund_sheet:top_similar:access',
        description: 'Permission to access top similar',
        params: [],
    },
    {
        key: 'fund_sheet:video:access',
        description: 'Permission to access funds videos',
        params: [],
    },
    {
        key: 'simulation_saving_plan:allocation:include',
        description: 'Permission to use allocation as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_saving_plan:portfolio:include',
        description: 'Permission to use portfolio as elements of the simulation',
        params: [],
    },
    {
        key: 'simulation_saving_plan:simulation:process',
        description: 'Permission to process a saving plan simulation',
        params: [
            {
                key: 'simulation_saving_plan:element_max',
                description: 'Projection saving plan max allocation',
                value: 2,
            },
        ],
    },
    {
        key: 'portfolio:model:manage',
        description: 'Permission to manage model portfolio',
        params: [],
    },
    {
        key: 'portfolio:module:access',
        description: 'Permission to access to the portfolio module',
        params: [],
    },
    {
        key: 'portfolio:real:manage',
        description: 'Permission to manage real portfolio',
        params: [],
    },
    {
        key: 'portfolio:reporting_pdf:access',
        description: 'Permission to print pdf of the portfolio reporting',
        params: [],
    },
    {
        key: 'portfolio:valuation:process',
        description: 'Permission to have valuation of model and real portfolio',
        params: [],
    },
    {
        key: 'contact:module:access',
        description: 'Permission to manage contacts',
        params: [],
    },
];

export const PORTFOLIO_SEARCH_FIELDS_PERMISSIONS: Record<string, { permissions: string[], resume: RESUME_FEATURES | null }> = {
    geographyWeights: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    categoryWeights: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    assetUniverseWeights: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    riskIndicator: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    sustainableFinanceWeights: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    assuranceVieWeights: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    rebalancingFrequency: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    amount: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
    calculatedSrri: {
        permissions: [PERMISSIONS.PORTFOLIO_VALUATION_PROCESS],
        resume: RESUME_FEATURES.PORTFOLIO_VALUATION_PROCESS,
    },
};
