import moize from 'moize';
import type { Options } from 'moize';
import {
    Observable,
    shareReplay,
} from 'rxjs';

type MemoizeOptions = Options & {
    isObservable?: boolean,
}

export function Memoize(opts?: MemoizeOptions) {
    return function memoizedFunc(target: any, key: PropertyKey, descriptor: PropertyDescriptor) {
        const originalMethod: (...args: unknown[]) => unknown = descriptor.value as (...args: unknown[]) => unknown;

        let updatedMethod: (...args: unknown[]) => unknown;

        if (opts?.isObservable) {
            const obj = {
                replaceMethod(...args: unknown[]): Observable<unknown> {
                    return (originalMethod as (...args: unknown[]) => Observable<unknown>).apply(this, args).pipe(
                        shareReplay(1),
                    );
                },
            };

            // eslint-disable-next-line @typescript-eslint/unbound-method
            updatedMethod = obj.replaceMethod;
        } else {
            updatedMethod = descriptor.value as (...args: unknown[]) => unknown;
        }

        const newFunction = moize(
            updatedMethod,
            {
                ...(opts || {}),
            },
        );

        // eslint-disable-next-line no-param-reassign
        descriptor.value = newFunction;
    };
}
