import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Inject,
    Injectable,
} from '@angular/core';
import {
    ActivatedRoute,
} from '@angular/router';
import {
    UntilDestroy,
} from '@ngneat/until-destroy';
import { Navigate } from '@ngxs/router-plugin';
import {
    Action,
    Actions,
    ofActionSuccessful,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import {
    combineLatest,
    EMPTY,
    Observable,
    of,
} from 'rxjs';
import {
    catchError,
    switchMap,
    tap,
} from 'rxjs/operators';

import { AuthenticationService } from '~/app/core/services/api/authentication/authentication.service';
import { ChallengeService } from '~/app/core/services/api/challenge/challenge.service';
import {
    HasPortfoliosAction,
    UpdateLastCheckSessionDateAction,
} from '~/app/core/state/application/application-action/application.actions';
import { ResetContactsWorkspaceAction } from '~/app/core/state/contacts-workspace/contacts-workspace.actions';
import { ResetPortfoliosWorkspaceAction } from '~/app/core/state/portfolios-workspace/portfolios-workspace.actions';
import { ResetCurrentDisplayValuesAction } from '~/app/core/state/shares-list/shares-list.actions';
import { ResetSharesWorkspaceAction } from '~/app/core/state/shares-workspace/shares-workspace.actions';
import { ChallengeResponseModel } from '~/app/shared/types/api/challenge/challenge-response-model.type';
import { AuthenticationTokens } from '~/app/shared/types/authentication/authentication-tokens.type';
import { LoginSuccessResponseExtended } from '~/app/shared/types/authentication/login-success-response-extended.type';
import { RefreshTokenSuccessResponse } from '~/app/shared/types/authentication/refresh-token-success-response.type';
import { Company } from '~/app/shared/types/company.type';
import { User } from '~/app/shared/types/user/user.type';

import {
    ActivateAccountAction,
    ChangeLanguageAction,
    LoginAction,
    LogoutAction,
    PasswordForgetAction,
    PasswordForgetErrorAction,
    PasswordResetAction,
    PasswordResetErrorAction,
    RefreshChallengeTokenAction,
    RefreshTokenAction,
    RefreshUserAction,
    ResetChallengeAction,
    SetTokensAction,
    SignupAction,
    UpdateIsChallengeRenewingAction,
    UpdateIsConsentAcceptedAction,
    UpdateLogosAction, UpdateTenantAction,
    UpdateUserAction,
    WhoamiAction
} from './authentication.actions';
import { BROADCAST_CHANNEL_AUTH_CHANGE_TOKEN } from '../../constants/broadcast-channels.constants';
import { CompaniesService } from '../../services/api/companies/companies.service';
import { ContactsService } from '../../services/api/contacts/contacts.service';
import { BroadcastChannelService } from '../../services/common/broadcast-channel/broadcast-channel.service';
import { ResetAuthorisationAction } from '../authorisation/authorisation.action';

export interface AuthenticationStateModel {
    token?: string | null,
    refreshToken?: string | null,
    loginError?: HttpErrorResponse | null,
    user?: User,
    company?: Company,
    challengeToken?: string | null,
    tenant?: string | null,
    instance?: string | null,
    isChallengeRenewing: boolean,
}

@UntilDestroy()
@State<AuthenticationStateModel>({
    name: 'authentication',
})
@Injectable()
export class AuthenticationState {
    public constructor(
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private contactService: ContactsService,
        private companiesService: CompaniesService,
        private broadcastChannelService: BroadcastChannelService,
        private challengeService: ChallengeService,
        private actions$: Actions,
        @Inject(DOCUMENT) private document: Document,
    ) {}


    @Selector()
    static token(state: AuthenticationStateModel) {
        return state.token;
    }

    @Selector()
    static isAuthenticated(state: AuthenticationStateModel): boolean {
        return !!state.token;
    }

    @Selector()
    static challengeToken(state: AuthenticationStateModel) {
        return state.challengeToken;
    }

    @Selector()
    static refreshToken(state: AuthenticationStateModel) {
        return state.refreshToken;
    }

    @Selector()
    static tenant(state: AuthenticationStateModel) {
        return state.tenant;
    }

    @Selector()
    static instance(state: AuthenticationStateModel) {
        return state.instance;
    }

    @Selector()
    public static loginError(state: AuthenticationStateModel) {
        return state.loginError;
    }

    @Selector()
    public static isChallengeRenewing(state: AuthenticationStateModel) {
        return state.isChallengeRenewing;
    }

    @Selector()
    public static user(state: AuthenticationStateModel) {
        return state.user;
    }

    @Selector()
    public static userCompany(state: AuthenticationStateModel) {
        return state.company;
    }

    @Selector()
    public static userCurrency(state: AuthenticationStateModel) {
        return state.user?.currency;
    }

    @Selector()
    public static userLanguage(state: AuthenticationStateModel) {
        // eslint-disable-next-line no-undef
        return state.user?.language?.toLowerCase() ?? navigator.language.substring(0, 2);
    }

    @Selector()
    public static userName(state: AuthenticationStateModel) {
        return state.user?.firstName;
    }

    @Selector()
    public static userFreeTrialUsed(state: AuthenticationStateModel): boolean | undefined {
        return state.user?.freeTrialUsed;
    }

    @Selector()
    public static userIsConsentAccepted(state: AuthenticationStateModel): boolean | undefined {
        return state.user?.isConsentAccepted;
    }

    @Action(ChangeLanguageAction)
    public changeLanguage({ patchState, getState, dispatch }: StateContext<AuthenticationStateModel>, action: ChangeLanguageAction) {
        let state = getState();

        if (!state.user) {
            return EMPTY;
        }

        const currentUserId = state.user?.id;

        if (!currentUserId) {
            return EMPTY;
        }

        return this.contactService.updateContact(currentUserId, { language: action.language })
            .pipe(
                switchMap(() => {
                    state = getState();

                    const { refreshToken } = state;

                    if (!refreshToken) {
                        return of();
                    }

                    dispatch(new RefreshTokenAction({ refreshToken }));

                    return this.actions$
                        .pipe(
                            ofActionSuccessful(RefreshTokenAction),
                        );
                }),
                tap(() => {
                    state = getState();

                    if (!state.user) {
                        return;
                    }

                    patchState({
                        user: {
                            ...state.user,
                            language: action.language,
                        },
                    });
                }),
                catchError(() => {
                    state = getState();

                    if (!state.user) {
                        return EMPTY;
                    }

                    patchState({
                        user: {
                            ...state.user,
                            language: action.language,
                        },
                    });

                    return EMPTY;
                }),
            );
    }

    @Action(LoginAction)
    public login({ patchState, dispatch }: { patchState: Function, dispatch: Function }, action: LoginAction) {
        patchState({
            loginError: null,
        });

        let redirect = '';

        this.route.queryParamMap.subscribe((params) => {
            redirect = params.get('redirect') || '';
        });

        return this.authService.login(action.payload)
            .pipe(
                tap((result) => {
                    patchState({
                        token: result.token,
                        refreshToken: result.refreshToken,
                        loginError: null,
                        user: result.userInfoModel,
                        instance: result.instance,
                        tenant: result.tenant,
                    });

                    this.broadcastChannelService.publish<AuthenticationTokens>(BROADCAST_CHANNEL_AUTH_CHANGE_TOKEN, {
                        token: result.token,
                        refreshToken: result.refreshToken,
                        instance: result.instance,
                        tenant: result.tenant,
                    });

                    dispatch(new ResetCurrentDisplayValuesAction());
                    dispatch(new Navigate([redirect]));
                }),
                catchError((err: HttpErrorResponse) => {
                    patchState({
                        loginError: err,
                    });

                    throw err;
                }),
            );
    }

    @Action(UpdateIsConsentAcceptedAction)
    public updateIsConsentAccepted({ patchState, getState }: StateContext<AuthenticationStateModel>, action: UpdateIsConsentAcceptedAction) {
        const state = getState();
        patchState({
            ...state,
            user: {
                ...state.user,
                isConsentAccepted: action.IsConsentAccepted,
            } as User,
        });
    }

    @Action(PasswordForgetAction)
    public passwordForget({ dispatch }: StateContext<AuthenticationStateModel>, action: PasswordForgetAction) {
        return this.authService.passwordForget(action.payload)
            .pipe(
                catchError((err) => {
                    dispatch(new PasswordForgetErrorAction(err));
                    throw err;
                }),
            );
    }

    @Action(PasswordResetAction)
    public passwordReset({ dispatch }: StateContext<AuthenticationStateModel>, action: PasswordResetAction) {
        return this.authService.passwordReset(action.payload)
            .pipe(
                catchError((err) => {
                    dispatch(new PasswordResetErrorAction(err));
                    throw err;
                }),
            );
    }

    @Action(SetTokensAction)
    public setTokens({ patchState }: StateContext<AuthenticationStateModel>, action: SetTokensAction) {
        patchState({
            ...action.authenticationTokens,
        });
    }


    @Action(RefreshTokenAction)
    public refreshToken(
        { patchState }: { patchState: Function, dispatch: Function },
        action: RefreshTokenAction,
    ): Observable<RefreshTokenSuccessResponse | undefined> {
        return this.authService.refreshToken(action.payload)
            .pipe(
                tap((result: RefreshTokenSuccessResponse) => {
                    patchState({
                        token: result.token,
                        refreshToken: result.refreshToken,
                    });

                    this.broadcastChannelService.publish<AuthenticationTokens>(BROADCAST_CHANNEL_AUTH_CHANGE_TOKEN, {
                        token: result.token,
                        refreshToken: result.refreshToken,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    throw err;
                }),
            );
    }

    @Action(ResetChallengeAction)
    public resetChallengeToken(
        { patchState }: { patchState: Function, dispatch: Function },
    ) {
        patchState({
            isChallengeRenewing: true,
        });
    }

    @Action(RefreshChallengeTokenAction)
    public refreshChallengeToken(
        { patchState }: { patchState: Function, dispatch: Function },
        action: RefreshChallengeTokenAction,
    ) {
        return this.challengeService.refreshChallengeToken(action.challenge)
            .pipe(
                tap((response: ChallengeResponseModel) => {
                    patchState({
                        challengeToken: response.token,
                        isChallengeRenewing: false,
                    });
                }),
                catchError((err: HttpErrorResponse) => {
                    throw err;
                }),
            );
    }

    @Action(UpdateIsChallengeRenewingAction)
    public updateIsChallengeRenewing({ patchState }: StateContext<AuthenticationStateModel>, action: UpdateIsChallengeRenewingAction) {
        patchState({ isChallengeRenewing: action.isChallengeRenewing });
    }


    @Action(ActivateAccountAction)
    public activateAccount({ patchState }: StateContext<AuthenticationStateModel>, action: ActivateAccountAction) {
        return this.authService
            .activateAccount(action.data)
            .pipe(
                tap((result: LoginSuccessResponseExtended) => {
                    patchState({
                        token: result.token,
                        refreshToken: result.refreshToken,
                        loginError: null,
                        user: result.userInfoModel,
                        instance: result.instance,
                        tenant: result.tenant,
                    });

                    this.broadcastChannelService.publish<AuthenticationTokens>(BROADCAST_CHANNEL_AUTH_CHANGE_TOKEN, {
                        token: result.token,
                        refreshToken: result.refreshToken,
                        instance: result.instance,
                        tenant: result.tenant,
                    });
                }),
            );
    }

    @Action(SignupAction)
    public signup(_: StateContext<AuthenticationStateModel>, action: SignupAction) {
        return this.authService.register({ email: action.email });
    }

    @Action(UpdateLogosAction)
    public updateLogos({ patchState }: StateContext<AuthenticationStateModel>, action: UpdateLogosAction) {
        return combineLatest([
            ...(action.logos.logoFile ? [this.companiesService.uploadLogo(action.logos.logoFile)] : [of(action.logos.logoFile)]),
            ...(action.logos.squareLogoFile ? [this.companiesService.uploadLogo(action.logos.squareLogoFile)] : [of(action.logos.squareLogoFile)]),
        ]).pipe(
            switchMap(([logoKey, squareLogoKey]) => this.companiesService.updateCompanies({
                ...(
                    logoKey !== undefined
                        ? {
                            logoFileKey: logoKey,
                        }
                        : {}
                ),
                ...(
                    squareLogoKey !== undefined
                        ? {
                            squareLogoFileKey: squareLogoKey,
                        }
                        : {}
                ),
            })),
            tap((company: Company) => {
                patchState({
                    company,
                });
            }),
        );
    }

    @Action(LogoutAction)
    public logout({ dispatch, patchState }: StateContext<AuthenticationStateModel>) {
        this.cleaningState(patchState, dispatch);
    }


    @Action(RefreshUserAction)
    public refreshUser({ patchState }: StateContext<AuthenticationStateModel>) {
        return this.authService.user()
            .pipe(
                tap((user: User) => {
                    patchState({
                        user,
                    });
                }),
            );
    }

    @Action(WhoamiAction)
    public whoami({ patchState, getState }: StateContext<AuthenticationStateModel>) {
        const { user } = getState();
        return this.authService.userCompany(user?.id)
            .pipe(
                tap((company: Company) => {
                    patchState({
                        company,
                    });
                }),
            );
    }

    @Action(UpdateUserAction)
    public updateUser({ patchState }: StateContext<AuthenticationStateModel>, action: UpdateUserAction) {
        patchState({
            user: action.user,
        });
    }

    @Action(UpdateTenantAction)
    public updateTenant({ patchState }: StateContext<AuthenticationStateModel>, action: UpdateTenantAction) {
        patchState({
            tenant: action.tenant,
        });
    }

    private cleaningState(patchState: (val: Partial<AuthenticationStateModel>) => AuthenticationStateModel, dispatch: (actions: any) => Observable<void>) {
        patchState({
            token: null,
            refreshToken: null,
            user: undefined,
            instance: undefined,
            tenant: undefined,
        });

        // Reset local storage
        dispatch(new ResetAuthorisationAction());
        dispatch(new UpdateLastCheckSessionDateAction(null));
        dispatch(new ResetContactsWorkspaceAction());
        dispatch(new ResetPortfoliosWorkspaceAction());
        dispatch(new ResetSharesWorkspaceAction());
        dispatch(new HasPortfoliosAction(false));
    }
}
