// eslint-disable-next-line max-classes-per-file
export class AddWorkspaceAction {
    static readonly type = '[PortfoliosWorkspace] Add workspace';

    constructor(public payload: { label?: string }) { }
}

export class ChangeActiveWorkspaceAction {
    static readonly type = '[PortfoliosWorkspace] Change active workspace';

    constructor(public key: string) { }
}

export class CloseWorkspaceAction {
    static readonly type = '[PortfoliosWorkspace] Close workspace';

    constructor(public key: string) { }
}

export class ResetPortfoliosWorkspaceAction {
    static readonly type = '[PortfoliosWorkspace] Reset workspaces';
}
