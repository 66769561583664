import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ChallengeRequestModel } from '~/app/shared/types/api/challenge/challenge-request-model.type';
import { ChallengeResponseModel } from '~/app/shared/types/api/challenge/challenge-response-model.type';

@Injectable({
    providedIn: 'root',
})
export class ChallengeService {
    constructor(private http: HttpClient) { }

    public refreshChallengeToken(challenge: string) {
        const requestBody: ChallengeRequestModel = { challenge };
        return this.http.post<ChallengeResponseModel>('/members/challenge', requestBody);
    }
}
