import { uniqBy as rUniqBy } from 'ramda';

import {
    PORTFOLIO_SEARCH_FIELDS_PERMISSIONS,
} from '~/app/core/constants/permissions.constants';
import { FacetType } from '~/app/shared/enums/facet-type.enum';
import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { ContactMetadata } from '~/app/shared/types/contacts/contact-metadata.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { FacetValue } from '~/app/shared/types/facet/facet-value.type';
import { Facet } from '~/app/shared/types/facet/facet.type';
import { PortfolioMetaData } from '~/app/shared/types/portfolio/portfolio-metadata.type';
import { SearchMetaData } from '~/app/shared/types/search/search-metadata.type';

function formatMetadataValues(field: string, facets: FacetValue[], defaultValues: FacetValue[] | undefined) {
    if (!defaultValues || (Array.isArray(defaultValues) && defaultValues.length === 0)) {
        return facets;
    }

    const groupedValues = [...(defaultValues || []), ...(facets || [])];
    const uniqByFacetValue = rUniqBy((facet: FacetValue) => facet.value || '');

    return uniqByFacetValue(groupedValues);
}

function formatFacetRiskProfile(facet: Facet): Facet {
    if (facet.field !== 'riskProfile'
        && facet.field !== 'srri'
        && facet.field !== 'riskProfileCalculated'
        && facet.field !== 'calculatedRiskProfile') {
        return facet;
    }
    let field = 'srri';
    switch (facet.field) {
        case 'riskProfileCalculated':
            field = 'srriCalculated';
            break;
        case 'calculatedRiskProfile':
            field = 'calculatedSrri';
            break;
        default:
            field = 'srri';
            break;
    }
    return {
        ...facet,
        field,
        type: FacetType.RISK_PROFILE,
    };
}

function formatFacetSustainableFinance(facet: Facet): Facet {
    if (facet.field !== 'isSustainableFinance' && facet.field !== 'sustainableFinanceWeights') {
        return facet;
    }
    return {
        ...facet,
        type: facet.field === 'isSustainableFinance' ? FacetType.SUSTAINABLE_FINANCE : FacetType.SUSTAINABLE_FINANCE_EXPO,
    };
}

function formatFacetValues(facet: Facet): Facet {
    return {
        name: facet.name,
        field: facet.field,
        type: facet.type,
        include: facet.include,
        intersect: facet.intersect,
        ...(facet.min ? { min: facet.min } : {}),
        ...(facet.max ? { max: facet.max } : {}),
        values: formatMetadataValues(facet.field, facet.values, facet.defaultValues),
    };
}

export function formatFacet(facet: Facet) {
    const transformers = [
        (currentFacet: Facet) => formatFacetRiskProfile(currentFacet),
        (currentFacet: Facet) => formatFacetValues(currentFacet),
        (currentFacet: Facet) => formatFacetSustainableFinance(currentFacet),
    ];

    return transformers.reduce((currentFacet: Facet, transform) => transform(currentFacet), facet);
}

export function filterEmptyFacetFamily(facetFamily: FacetFamily) {
    const values = facetFamily.values.filter((item) => {
        if (item.type === FacetType.MULTI_CHOICE && Array.isArray(item.values) && !item.values.length) {
            return false;
        }
        return true;
    });

    return values.length > 0;
}

export function addPortfolioFacetFamilyPermissions(facetFamily: FacetFamily): FacetFamily {
    let permissions: string[] = [];
    let resume: RESUME_FEATURES | null = null;

    if (facetFamily.values.length === 1) {
        const foundFacet = facetFamily.values[0];
        if (PORTFOLIO_SEARCH_FIELDS_PERMISSIONS[foundFacet.field]) {
            permissions = PORTFOLIO_SEARCH_FIELDS_PERMISSIONS[foundFacet.field].permissions;
            resume = PORTFOLIO_SEARCH_FIELDS_PERMISSIONS[foundFacet.field].resume;
        }
    }

    return {
        ...facetFamily,
        permissions,
        resume,
        values: facetFamily.values.map((facet) => ({
            ...facet,
            permissions: PORTFOLIO_SEARCH_FIELDS_PERMISSIONS[facet.field]?.permissions ?? [],
            resume: PORTFOLIO_SEARCH_FIELDS_PERMISSIONS[facet.field]?.resume ?? null,
        })),
    };
}

export function formatMetadatasShare(
    metadata: SearchMetaData,
): SearchMetaData {
    return {
        facets: metadata.facets
            .map((facetFamily) => ({
                name: facetFamily.name,
                field: facetFamily.field,
                values: facetFamily.values
                    .map((facet: Facet) => formatFacet(facet)),
            }))
            .filter(filterEmptyFacetFamily),
        subSegmentations: metadata.subSegmentations,
    };
}

export function formatMetadatasPortfolio(metadata: PortfolioMetaData): PortfolioMetaData {
    return {
        facets: metadata.facets
            .map((facetFamily) => ({
                name: facetFamily.name,
                field: facetFamily.field,
                values: facetFamily.values
                    .map((facet: Facet) => formatFacet(facet)),
            }))
            .filter(filterEmptyFacetFamily)
            .map(addPortfolioFacetFamilyPermissions),
        subSegmentations: metadata.subSegmentations,
        alertTypeRefs: metadata.alertTypeRefs,
    };
}

export function formatMetadatasContact(
    metadata: ContactMetadata,
): ContactMetadata {
    return {
        // Adapt format like portfolio / share facets
        facets: metadata.facets?.[0].values
            .map((facetFamily) => ({
                name: facetFamily.name,
                field: facetFamily.field,
                values: [formatFacet(facetFamily)],
            }))
            .filter(filterEmptyFacetFamily),
        subSegmentations: metadata.subSegmentations,
    };
}
