// eslint-disable-next-line max-classes-per-file
export class GetAuthorisationAction {
    public static readonly type = '[AuthorisationState] Get Authorisation data from api and populate state';

    constructor(public isAPICallForced?: boolean) { }
}

export class ResetAuthorisationAction {
    public static readonly type = '[AuthorisationState] Reset Authorisation state to default value';
}
