export enum FacetType {
    BOOLEAN = 'boolean',
    BOOLEAN_CHOICE = 'multi-choice-boolean',
    MULTI_CHOICE = 'multi-choice',
    PRICE = 'price',
    RANGE = 'range',
    RANGE_PERCENTAGE = 'range-percentage',
    SINGLE_CHOICE = 'single-choice',
    RISK_PROFILE = 'risk-profile',
    RISK_INDICATOR = 'risk-indicator',
    RISK_INDICATORS = 'risk-indicators',
    NOTATIONS = 'notations',
    SUSTAINABLE_FINANCE = 'sustainable-finance',
    SUSTAINABLE_FINANCE_EXPO = 'sustainable-finance-expo',
    EXPO = 'expo',
    DATE = 'range-date',
    MULTI_BOOLEAN = 'multi-boolean',
}
