// eslint-disable-next-line max-classes-per-file
export class AddWorkspaceAction {
    static readonly type = '[SharesWorkspace] Add workspace';

    constructor(public name?: string) { }
}

export class ChangeActiveWorkspaceAction {
    static readonly type = '[SharesWorkspace] Change active workspace';

    constructor(public key: string) { }
}

export class CloseWorkspaceAction {
    static readonly type = '[SharesWorkspace] Close workspace';

    constructor(public key: string) { }
}

export class ResetSharesWorkspaceAction {
    static readonly type = '[SharesWorkspace] Reset workspaces';
}
