export enum SIMULATION_HISTORICAL_PERMISSIONS {
    'SIMULATION_HISTORICAL_ALLOCATION_INCLUDE' = 'simulation_historical:allocation:include', // 'Permission to use allocations as elements of the simulation'
    'SIMULATION_HISTORICAL_BENCHMARK_DEFINE' = 'simulation_historical:benchmark:define', // 'Permission to use an elements of the simulation as a benchmark'
    'SIMULATION_HISTORICAL_CATEGORY_INCLUDE' = 'simulation_historical:category:include', // 'Permission to use categories as elements of the simulation'
    'SIMULATION_HISTORICAL_FUND_INCLUDE' = 'simulation_historical:fund:include', // 'Permission to use funds as elements of the simulation'
    'SIMULATION_HISTORICAL_PORTFOLIO_INCLUDE' = 'simulation_historical:portfolio:include', // 'Permission to use portfolios as elements of the simulation'
    'SIMULATION_HISTORICAL_PROCESS' = 'simulation_historical:simulation:process',
    'SIMULATION_HISTORICAL_TOP_SIMILAR_ACCESS' = 'simulation_historical:top_similar:access',
    'SIMULATION_HISTORICAL_PDF_ACCESS' = 'simulation_historical:pdf:access',
}
